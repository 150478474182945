@import "~assets/scss/variables";


.form-group .input-group.field-number-range {
  border: $field-border;
  border-radius: $field-border-radius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .form-control {
    border: unset;

    &:focus {
      border: unset;
      box-shadow: unset;
    }

    &.field-number-range-separator {
      width: 0.75rem;
      flex-grow: unset;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
