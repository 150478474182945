@import "~assets/scss/variables";

.app-layout-inner {
  .ant-modal-mask, .dialog {
    position: absolute;
    z-index: 100;
  }
}

.dialog {
  .ant-modal-header {
    padding: 0;
  }

  .ant-modal-body {
    padding: 16px 16px 6px 16px !important;

    .form-group:last-child {
      margin-bottom: 0;
    }
  }

  .dialog-title {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-black;
    color: $color-white;
  }

  .dialog-close {
    color: $color-white;
    background-color: transparent;

    &:hover {
      color: $color-red;
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
