.form-group .form-control.field-bool {
  display: flex;
  align-items: center;
  background-color: unset;
  border: 1px solid transparent;
  padding: 0;

  & > label {
    display: inline-flex;
  }
}
