/*------------------------------ WRAPPER -------------------------------*/

.widget-wrapper {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.widget-empty-table{
  .ant-table table{
    width: auto;
  }
}

/*------------------------------ FILTERS -------------------------------*/

.widget-filters-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #ced4da;
}

.widget-filter-wrapper {
  margin: 0;
  padding: 5px;
  width: 100%;
}

.widget-search-wrapper {
  margin: 0;
  padding: 5px;
  width: 100%;
}

@media screen and (max-width: 664px) and (min-width: 480px) {
  .widget-filters-wrapper {
    flex-wrap: wrap;
  }

  .widget-filter-wrapper {
    flex: 1 1 0;
    min-width: 175px;
  }

  .widget-search-wrapper {
    flex: 2 1 0;
    min-width: 350px;
  }
}

@media screen and (max-width: 479px) {
  .widget-filters-wrapper {
    flex-direction: column;
  }
}

/* Result */

.widget-result-wrapper {
  width: 100%;
}

/* Selected */

.widget-selected-wrapper {
  display: block;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

/*-------------------------------- TABLE -------------------------------*/

.widget-column {
  font-size: 13px;
  font-family: sans-serif !important;
  font-weight: 500 !important;
  color: #564f4f !important;
}

.widget-column-btn {
  width: 34px;
  padding-right: 0 !important;
}

.widget-column-name {
  width: 214px;
}

.widget-column-building {
  min-width: 110px;
  max-width: 200px;
}

.widget-column-floor {
  min-width: 90px;
  max-width: 100px;
}

.widget-column-capacity {
  min-width: 90px;
  max-width: 100px;
}

@media screen and (max-width: 699px) {
  .widget-column-name {
    width: 129px;
  }
}

/*------------------------------- LOADER -------------------------------*/
.widget-loading-indicator {
  position: fixed;
  z-index: 9999;
  height: 50px;
  width: 50px;
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}