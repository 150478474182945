@import "~assets/scss/variables";


.eventops-button {
  padding: 0.25rem !important;

  .svg-inline--fa {
    width: 100% !important;
    height: 100% !important;
  }

  &.filter-button .svg-inline--fa {
    width: 85% !important;
    height: 85% !important;
    margin-top: 7.5%;
  }

  &:disabled {
    opacity: 0.5 !important;
  }
  // Red buttons
  &.minus-button, &.cancel-button, &.trash-button, &.red-button {
    color: $color-red !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-red, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-red, $shadow-oppacity));
    }
  }

  &.minus-button-solid {
    color: $color-white !important;
    background-color: $color-red !important;
  }
  // Green buttons
  &.add-button, &.edit-button, &.save-button, &.ok-button, &.filter-button, &.green-button {
    color: $color-green !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-green, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-green, $shadow-oppacity));
    }
  }

  &.add-button-solid, &.edit-button-solid {
    color: $color-white !important;
    background-color: $color-green !important;
  }
  // Blue buttons
  &.download-button, &.blue-button {
    color: $color-blue !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-blue, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-blue, $shadow-oppacity));
    }
  }

  &.download-button-solid {
    color: $color-white !important;
    background-color: $color-blue !important;
  }
  // Orange buttons
  &.orange-button {
    color: $color-orange !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-orange, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-orange, $shadow-oppacity));
    }
  }
  // teal buttons
  &.teal-button {
    color: $color-teal !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-teal, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-teal, $shadow-oppacity));
    }
  }
  // purple buttons
  &.purple-button {
    color: $color-purple !important;
    background-color: transparent !important;
    transition: filter $shadow-delay !important;
    -webkit-transition: -webkit-filter $shadow-delay !important;

    &:hover {
      filter: drop-shadow(0 0 $shadow-size rgba($color-purple, $shadow-oppacity));
      -webkit-filter: drop-shadow(0 0 $shadow-size rgba($color-purple, $shadow-oppacity));
    }
  }
}

.danger-button {
  color: $color-white !important;
  background-color: $color-red !important;
}

//removes shadow from ant btn
.ant-btn {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.icon-bg-white {
  background-color: #fff !important;
}

.icon-bg-black {
  background-color: #213443 !important;
}

.icon-bg-blue {
  background-color: #366f9a !important;
}

.icon-bg-blue-light {
  background-color: #92bcc1 !important;
}

.icon-bg-green {
  background-color: #51952e !important;
}

.icon-bg-green-light {
  background-color: #abcb9c !important;
}

.icon-bg-orange {
  background-color: #d57b3d !important;
}

.icon-bg-orange-light {
  background-color: #ebbfa2 !important;
}

.icon-bg-red {
  background-color: #ba2d2d !important;
}

.icon-bg-red-light {
  background-color: #e09a99 !important;
}

.icon-bg-teal {
  background-color: #1b8b8c !important;
}

.icon-bg-purple {
  background-color: #775aca !important;
}

.icon-bg-text {
  background-color: #564f4f !important;
}
