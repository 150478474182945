.fc {
    background-color: white;
}

.fc-daygrid-day {
    cursor: pointer !important;
}

.fc-col-header-cell-cushion  {
    color: unset;
    text-decoration: none !important;
    cursor: unset !important;
}

.fc-list-day-side-text  {
    color: unset;
    text-decoration: none !important;
    cursor: unset !important;
}

.fc-list-day-text  {
    color: unset;
    text-decoration: none !important;
    cursor: unset !important;
}

body .fc .fc-event {
    cursor: pointer;
}

.calendar-view .fc .fc-event {
    background-color: white;
    color: unset;
    &:hover {
        background-color: unset;
    }
}
