@import "~assets/scss/variables";

.form-group {
  .form-control {
    color: $color-text;
    border-color: $field-border-color;
    font-weight: 500;

    &::placeholder {
      color: $color-gray-dark;
      opacity: 1;
    }
    // Ant Design to Bootstrap - Start
    &.ant-input-number, &.ant-select, &.ant-select.ant-select-multiple {
      height: unset;
      padding: 0;

      .ant-select-selector {
        height: unset;
        border: unset !important;
        border-radius: unset;
        box-shadow: unset !important;
        background-color: unset;

        .ant-select-selection-item {
          font-weight: unset;
        }
      }
    }

    &.ant-select-focused, &.ant-picker-focused {
      color: $color-text;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    &.ant-picker {
      &:hover {
        border-color: $field-border-color;
      }

      input {
        color: $color-text;
        font-weight: 500;
      }

      .ant-picker-input input {
        font-size: unset;
      }
    }

    &.ant-picker-range {
      display: inline-flex;
    }
    // Ant Design to Bootstrap - End
  }

  .horizontal-label {
    text-align: right;
    padding-right: 5px;

    label {
      display: inline-flex;
      align-items: center;
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  &.form-group-horizontal {
    display: flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin-bottom: 24px;

    .form-label {
      //overflow: hidden; <- Cutting letters when label collapses
      text-align: right;

      label {
        display: inline-flex;
        align-items: center;
        height: calc(1.5em + 0.75rem + 2px);
        margin: 0;
        padding-right: 8px;
        width: 100%;

        > span {
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }

    @media screen and (max-width: 575px) {
      .form-label {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 0 8px;
        line-height: 1.5715;
        white-space: initial;
        text-align: left;
      }

      .form-input {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &.form-group-disabled, &.form-group-readonly {
    .form-control:not(.field-bool) {
      background-color: $color-gray-light;

      &.ant-select, &.ant-select.ant-select-multiple {
        .ant-select-selector {
          color: unset;
          background: $color-gray-light;

          .ant-select-selection-item {
            color: unset;
            background: unset;
          }
        }
      }

      &.ant-picker.ant-picker-disabled {
        background: $color-gray-light;
        border-color: $field-border-color;

        input {
          color: unset;
          background: unset;
        }

        .ant-picker-suffix {
          display: none;
        }
      }
    }
  }

  &.form-group-disabled .form-control:not(.field-bool) {
    cursor: not-allowed;
  }

  &.form-group-readonly .form-control:not(.field-bool) {
    cursor: default;

    &:focus {
      border-color: $field-border-color;
      box-shadow: unset;
    }

    &.ant-select, &.ant-select.ant-select-multiple {
      .ant-select-selector {
        cursor: default;

        .ant-select-selection-item, input {
          cursor: default;
        }
      }
    }

    &.ant-picker input[readonly] {
      cursor: unset;
    }
  }
}

.form-divider {
  width: 100%;
  margin-bottom: 1rem !important;

  .ant-divider.ant-divider-horizontal {
    .ant-divider-inner-text {
      font-weight: 500;
    }
  }
}

.custom-field-file .ant-upload.ant-upload-select {
  display: block;
}

.field-hidden {
  margin: 0 -5px 13px -5px;
  padding: 5px;
  border: 2px dashed $color-gray-dark;
  border-radius: 4px;
}

.input-loading {
  position: relative;

  .spinner {
    left: unset;
    right: 4px;
    height: calc(1.5em - 8px + 0.75rem);
    width: calc(1.5em - 8px + 0.75rem);
  }
}

.ant-select-arrow .spinner {
  left: unset;
  right: -4px;
  height: calc(1.5em - 8px + 0.75rem);
  width: calc(1.5em - 8px + 0.75rem);
}

.color-picker {
  margin-left: -1px;

  .color-picker-preview {
    height: 100%;
    width: 2.8rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer
  }

  .color-picker-popover {
    position: absolute;
    top: -300px;
    right: 0;
    z-index: 102;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  .color-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.eventops-button.button-upload .svg-inline--fa {
  width: 1em !important;
  height: unset !important;
}

.inline-view .inline-view-button {
  display: none;
}

.inline-view:hover .inline-view-button {
  display: unset;
}

.inline-edit {
  display: flex;
}
