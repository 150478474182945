@import "~assets/scss/variables";

.spinner.fa-spinner-third {
  color: $color-blue;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 50%;
  max-height: 6em;
  width: 50%;
  max-width: 6em;
}

.content-spinner {
  height: 100%;
  transition: opacity 2000ms;

  .ant-spin.ant-spin-spinning {
    color: $color-blue;
    max-height: unset;

    .ant-spin-dot {
      height: 50%;
      max-height: 6em;
      width: 50%;
      max-width: 6em;
    }
  }

  .ant-spin-container {
    height: 100%;
  }
}
